import React from 'react';
import './WorthItCalc.css';
export default function WIButtons({questions, selectedQuestion, handleSelectAnswer}){

    return(

        <div className='wi-buttons'>
            {
                questions[selectedQuestion].answerOptions.map((answerOption,i) => (
                    <div className='worth-it-calc-answer-option' key = {i} onClick = {()=>handleSelectAnswer(i)}>{answerOption}</div> 
                ))
            }
        </div>

    )

}