import React from 'react';
import WorthItCalculator from '../components/WorthItCalculator/WorthItCalc';

export default function pages (){

    return(

        <WorthItCalculator />

    )

}