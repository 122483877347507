import React from 'react';
import {useState, useEffect} from 'react';
import WIButtons from './WIButtons';
import './WorthItCalc.css';
import { track } from '../../../tracking'
const questions = [
    {
        questionText: 'How many times do you drink enough to get hungover per month?',
        answerOptions: ['1-2','3-5','6-10','10+'],
    },
    {
        questionText: 'When you’re hungover, how much do you spend on greasy hangover food, electrolyte drinks, drugs, or whatever it takes to make you feel better?',
        answerOptions: ['$0','$1-$5','$6-$10','$11-25','$25+'],
    },
    {
        questionText: 'How much is your time worth?',
        answerOptions: ['$0/hr','$10/hr','$25/hr','$50/hr','$100/hr', '$500/hr+ '],
    }

]

export default function WorthItCalculator(){

    const [selectedQuestion, setSelectedQuestion] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [isComplete, setIsComplete] = useState(false);
    const [worthItStats, setWorthItStats] = useState({})


    useEffect(()=>{
        calcWorthIt(selectedAnswers)
    },[isComplete])

    function handleSelectAnswer(answerIndex){
        let props = {
            question : questions[selectedQuestion].questionText,
            answer : questions[selectedQuestion].answerOptions[answerIndex]
        }

        track(`WIC Answer Question-${selectedQuestion}`, props )
        if(selectedQuestion < questions.length - 1){
            setSelectedAnswers([...selectedAnswers, answerIndex]);
            setSelectedQuestion(selectedQuestion + 1);
        }else{
            setSelectedAnswers([...selectedAnswers, answerIndex]);
            setIsComplete(true);
        }
        
    }

    function handleReset(){
        setSelectedQuestion(0);
        setSelectedAnswers([]);
        setIsComplete(false);
    }

    function calcWorthIt(selectedAnswers){

        const vals = getAllVals(selectedAnswers)

        const hangovers = vals[0]
        const hangoverCost = vals[1]
        const timeWorth = vals[2]
        
        const hangoversPerYear = hangovers * 12
        let hangoverCostPerYear = hangoverCost * hangoversPerYear
    
        const timeLost = hangoversPerYear * 4
        const timeLostCost = timeLost * timeWorth
        const {benderCost, benderReq} = calcBenderCost(hangoversPerYear)


        const hangoverRemedySavings = hangoverCostPerYear - benderCost

        setWorthItStats({hangoversPerYear, hangoverCostPerYear, timeLost, timeLostCost, hangoverRemedySavings})
    }


    return(

        <div className='worth-it-calc-container'>
            {!isComplete? 
            <>
                <div className='worth-it-calc-question'>
                    {questions[selectedQuestion]?.questionText}
                </div>
                <WIButtons selectedQuestion = {selectedQuestion} questions={questions} handleSelectAnswer = {handleSelectAnswer}/>
            </>
            :
            <div className='worth-it-calc-results'>
                <div className = 'wic-rr'>You experience roughly <strong>{worthItStats.hangoversPerYear}</strong> hangovers per year and spend around <strong>${worthItStats.hangoverCostPerYear}</strong> on hangover remedies.</div>
                
                <div className = 'wic-rr'>If each hangover costs you 4 hrs of productive time. You lose <strong>{worthItStats.timeLost}</strong> hours per year to hangovers, costing you roughly <strong>${worthItStats.timeLostCost}</strong>.</div>
    
                <div className = 'wic-rr'>
                    {worthItStats.hangoverRemedySavings > 0? 
                        <>With bender, you would save <strong>${worthItStats.hangoverRemedySavings.toFixed(2)}</strong> per year on hangover remedies </>
                    :
                        <>With bender, you would spend <strong>${Math.abs(worthItStats.hangoverRemedySavings).toFixed(2)}</strong> per year on hangover remedies </>
                    }
            
                    and save <strong>${worthItStats.timeLostCost}</strong> in lost time.
                </div>
                <div className='wic-reset-button' onClick={handleReset}>Reset</div>
            </div>
            }
            
        </div>

    )

}


function ansToVal(quid, ansid){
    const vals = [[1.5,4,8,10], [0,3.6,8,18,25], [0,10,25,50,100,500]]
    return vals[quid][ansid]
}

function getAllVals(selectedAnswers){
    let vals = []
    for(let i = 0; i < selectedAnswers.length; i++){
        vals.push(ansToVal(i, selectedAnswers[i]))
    }
    //vals = hangovers / month, hangover cost, time worth
    return vals
}

function calcBenderCost(hangoversPerYear){
    let benderReq = hangoversPerYear / 7 
    let benderCost = 0
    if(benderReq < 3){
        benderCost = benderReq * 24.99
    }else if(benderReq >= 3 && benderReq < 6){
        benderCost = benderReq * (24.99 * .8)
    }else if(benderReq >= 6 && benderReq < 12){
        benderCost = benderReq * (24.99 * .75)
    }else if(benderReq >= 12 ){
        benderCost = benderReq * (24.99 * .7)
    }
   
    return {benderCost, benderReq }
}

